<template>
  <v-container grid-list-md>
    <v-card style="margin-bottom:5em">
      <v-card-title>Study Abroad Parent/Guardian Signature</v-card-title>
      <v-card-text v-if="!id">
        <p>It looks like something went wrong with the link. Please try the link again. If you copy-and-pasted the link, make sure to include the full code at the end of the link.</p>
      </v-card-text>
      <v-card-text v-else-if="!validated">
        <p>Please enter your email address and your student's first name to verify access to this record.</p>
        <v-row>
          <v-col :cols="12" md="6">
            <v-text-field ref="nameRef" v-model="name" label="Student's First Name" outlined @keydown.enter="validate"></v-text-field>
          </v-col>
          <v-col :cols="12" md="6">
            <v-text-field ref="emailRef" v-model="email" :rules="emailRules" label="Email Address" outlined @keydown.enter="validate"></v-text-field>
          </v-col>
        </v-row>
        <v-alert v-if="error" style="margin:1em" color="error" icon="fas fa-exclamation-triangle" dark>The email address or name you entered does not match our records for the study abroad code. Please make sure you enter the same email as the one to which you received the link to this page and just your student's first name.</v-alert>
        <v-btn @click="validate" style="margin-top:1em">{{ validating ? 'Checking...' : 'Continue' }}</v-btn>
      </v-card-text>
      <v-card-text v-else>
        <p>Please review the information that the student has entered and provide your signature. Note that if the student changes the program or the term they wish to study abroad, you will be asked to sign again.</p>
        <v-row>
          <v-col>
            <v-text-field :value="program" label="Program" readonly></v-text-field>
          </v-col>
          <v-col>
            <v-text-field :value="programTerm" label="Program Term" readonly></v-text-field>
          </v-col>
        </v-row>
        <h4>Course{{ courses.length > 1 ? 's' : '' }} to be taken:</h4>
        <v-row v-for="{ course, credits } in courses" :key="course">
          <v-col>
            <v-text-field :value="course" label="Course" readonly></v-text-field>
          </v-col>
          <v-col>
            <v-text-field :value="credits" label="Credit Hours" readonly></v-text-field>
          </v-col>
        </v-row>
        <img v-if="signature.signature" :src="signature.signature" style="width:300px;height:150px;display:block;background-color:white" />
        <div v-if="signature.name" style="font-size:1.5em;font-weight:bold;margin:0 1em 0 1em;">{{ signature.name }}</div>
        <div v-if="signature.date" style="font-size:1em;font-weight:bold;margin:0 1em 1em 1.5em;">Signed: {{ stringFormatDate(signature.date) }}</div>
        <signature-pad @save="saveSignature" :show-enter-name="true" :text="signature.signature ? 'Re-do Signature' : 'Add Parent/Guardian Signature'"></signature-pad>
      </v-card-text>
      <v-alert v-if="saved" type="info">The signature has been saved. Thank you! You may now leave this page or print a copy for your records.</v-alert>
    </v-card>
  </v-container>
</template>
<style>
.v-text-field.v-input--is-readonly > .v-input__control > .v-input__slot:before {
  border-width: 0
}
div.row.no-bottom-padding > div {
  padding-bottom: 0
}
</style>
<script>
import { ref, computed } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'

export default {
  components: {
    SignaturePad: () => import('@/components/forms/signaturePad')
  },
  setup (props, { root }) {
    const id = computed(() => root.$router.currentRoute.params.id)
    const nameRef = ref(null)
    const name = ref('')
    const emailRef = ref(null)
    const email = ref('')
    const emailRules = ref([
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ])
    const error = ref(false)

    const service = root.$feathers.service('student/study-abroad')

    const validating = ref(false)
    const validated = ref(false)
    function validate () {
      if (email.value !== '' && !emailRef.value.hasError && name.value !== '') {
        validating.value = true
        const $regex = '^' + name.value + ' '
        service.find({ query: { validate: id.value, name: { $regex, $options: 'i' }, 'guardianSignature.email': email.value.toLowerCase() } }).catch((e) => {
          alert(e)
        }).then(({ data }) => {
          if (data.length > 0) {
            error.value = ''
            validated.value = true
            program.value = data[0].program
            programTerm.value = data[0].programTerm
            courses.value = data[0].courses
            signature.value = data[0].guardianSignature
          } else {
            error.value = true
          }
        }).finally(() => {
          validating.value = false
        })
      } else if (name.value === '') {
        nameRef.value.focus()
      } else if (email.value === '') {
        emailRef.value.focus()
      }
    }

    const program = ref('')
    const programTerm = ref('')
    const courses = ref([])
    const signature = ref({ signature: false, name: false, date: null })
    const saved = ref(false)

    async function saveSignature (obj) {
      try {
        const data = await service.patch(id.value, { 'guardianSignature.name': obj.name, 'guardianSignature.signature': obj.signature, 'guardianSignature.date': obj.date })
        signature.value = data.guardianSignature
        saved.value = true
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error saving signature: ' + e })
        console.log(e)
      }
    }

    return {
      id,
      nameRef,
      name,
      emailRef,
      email,
      emailRules,
      error,
      validating,
      validated,
      validate,
      program,
      programTerm,
      courses,
      signature,
      saved,
      saveSignature,
      stringFormatDate
    }
  }
}
</script>
